














































import { Component, Vue, Watch } from "vue-property-decorator";
import EventBus from "@/store/eventBus";

import animatedScrollTo, { IOptions } from "animated-scroll-to";

interface Mensagem {
    id?: number;
    tipo: string;
    foto: string;
    nome: string;
    hora: string;
    mensagem: string;
}

@Component({})
class ChatPage extends Vue {
    // ============== Ciclo de vida =============
    private mounted(): void {
        this.onResize();
        window.addEventListener("resize", this.onResize, true);
    }

    private messages: Mensagem[] = [
        {
            id: 0,
            tipo: "Aluna",
            foto: "chat-p1.jpg",
            nome: "Mariana",
            hora: "19:44",
            mensagem:
                "Olá Bruno, boa noite. Tudo joia? Gostaria de tirar uma dúvida contigo."
        },
        {
            id: 1,
            tipo: "Instrutor",
            foto: "chat-p2.jpg",
            nome: "Bruno",
            hora: "19:53",
            mensagem: "Oi Mariana, boa noite. Pode falar!"
        },
        {
            id: 2,
            tipo: "Aluna",
            foto: "chat-p1.jpg",
            nome: "Mariana",
            hora: "19:57",
            mensagem:
                "Não poderei ir para a próxima aula 😔, como posso fazer para remarcar?"
        },
        {
            id: 3,
            tipo: "Instrutor",
            foto: "chat-p2.jpg",
            nome: "Bruno",
            hora: "19:59",
            mensagem:
                "Tudo bem, sem problema. Vou te passar os detalhes para você poder remarcar. 👍🏼"
        },
        {
            id: 4,
            tipo: "Aluno",
            foto: "chat-p3.jpg",
            nome: "Gustavo",
            hora: "10:24",
            mensagem:
                "Professor, bom dia. Na próxima aula gostaria derever um ponto da aula anterior."
        },
        {
            id: 5,
            tipo: "Instrutor",
            foto: "chat-p4.jpg",
            nome: "Márcio Vitor",
            hora: "10:30",
            mensagem:
                "Olá Gustavo, bom dia. Se tiver ficado com alguma dúvida, podemos rever na aula."
        },
        {
            id: 6,
            tipo: "Aluna",
            foto: "chat-p3.jpg",
            nome: "Gustavo",
            hora: "10:32",
            mensagem:
                "Certo, ótimo então! Na sexta explico melhor qual foi a dúvida. Obrigado 👍🏽 "
        },
        {
            id: 7,
            tipo: "Instrutor",
            foto: "chat-p4.jpg",
            nome: "Márcio Vitor",
            hora: "10:35",
            mensagem: "De nada, até a próxima aula Gustavo! 👋"
        },
        {
            id: 8,
            tipo: "Aluna",
            foto: "chat-p1.jpg",
            nome: "Mariana",
            hora: "19:44",
            mensagem:
                "Olá Bruno, boa noite. Tudo joia? Gostaria de tirar uma dúvida contigo."
        },
        {
            id: 9,
            tipo: "Instrutor",
            foto: "chat-p2.jpg",
            nome: "Bruno",
            hora: "19:53",
            mensagem: "Oi Mariana, boa noite. Pode falar!"
        },
        {
            id: 10,
            tipo: "Aluna",
            foto: "chat-p1.jpg",
            nome: "Mariana",
            hora: "19:57",
            mensagem:
                "Não poderei ir para a próxima aula 😔, como posso fazer para remarcar?"
        }
    ];
    private offsetTop: number = 0;
    private chatBreakPoint: number = 0;
    private interval: number = 0;
    private intervalLoaded: boolean = false;
    private animationLoaded: boolean = false;
    private overPage: boolean = false;

    // ========= Variáveis computadas ==========

    private get scrollPosition(): number {
        return EventBus.scrollPosition;
    }

    private get position(): number {
        return Math.max(
            Math.min(
                (this.scrollPosition - this.offsetTop) /
                    (window.innerHeight * 0.7),
                1
            ),
            0
        );
    }

    private onResize(): void {
        this.offsetTop = (this.$refs.chat as HTMLElement).offsetTop;
        this.chatBreakPoint =
            (this.$refs.chatContainer as HTMLElement).offsetTop +
            window.innerHeight;
    }

    // ============== Observadores ==============
    @Watch("position")
    private onScrollPositionChange(): void {
        if (this.position > 0.7) {
            this.animationLoaded = true;

            if (!this.intervalLoaded) {
                this.intervalLoaded = true;
                let index: number = 1;

                this.interval = setInterval(() => {
                    const chat = this.$refs.chatWrapper as HTMLElement;
                    const element: HTMLElement = document.getElementById(
                        "chat" + index
                    )!;

                    const options: IOptions = {
                        cancelOnUserAction: false,
                        easing: t => --t * t * t + 1,
                        elementToScroll: chat,
                        horizontalOffset: 0,
                        maxDuration: 3000,
                        minDuration: 500,
                        speed: 100,
                        verticalOffset: 0
                    };

                    animatedScrollTo(element, options).then(() => {
                        index += 1;
                        if (index == 9) {
                            chat.scrollTo(0, 0);
                            index = 1;
                        }
                    });
                }, 3000);
            }
        } else {
            this.animationLoaded = false;
        }
    }

    private teste(e: Event) {
        e.stopPropagation();
    }
}

export default ChatPage;
