import Vue from 'vue';

interface IEventBus {
    scrollPosition: number;
    scrollDirection: string;
    scrollY: number;
}

const eventBus: IEventBus = Vue.observable({
    scrollDirection: 'down',
    scrollPosition: 0,
    scrollY: 0
});

export default eventBus;