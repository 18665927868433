





























import { Component, Vue } from "vue-property-decorator";

@Component({})
class AvaliarPage extends Vue {}

export default AvaliarPage;
