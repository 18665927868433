











import { Component, Vue } from "vue-property-decorator";

@Component({})
class BiometriaPage extends Vue {}

export default BiometriaPage;
