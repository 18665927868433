import Vue from 'vue';
import App from './App.vue';

import VueNumber from 'vue-number-animation';
Vue.use(VueNumber);

// ========== Vee validate ==========
import { extend, localize } from 'vee-validate';
import { required, email, min, confirmed, max } from 'vee-validate/dist/rules';
import pt_BR from './utils/validate-pt_BR.json';

// rules.
extend('required', required);
extend('email', email);
extend('min', min);
extend('confirmed', confirmed);
extend('max', max);
extend('min', min);

// Install English and Arabic localizations.
localize({
    pt_BR: {
        messages: pt_BR.messages
    }
});
localize('pt_BR');

import VsoftUi from './plugins/vsoftui';
Vue.use(VsoftUi);

import Device from './plugins/device';
Vue.use(Device);

import Vue2TouchEvents from 'vue2-touch-events';
Vue.use(Vue2TouchEvents);

import VueAnalytics from 'vue-analytics';

Vue.use(VueAnalytics, {
    id: 'UA-40654317-4',
    autoTracking: {
        screenview: true
    }
});
Vue.config.productionTip = false;

new Vue({
    render: h => h(App),
}).$mount('#app');
