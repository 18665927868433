










































































import { Component, Vue } from "vue-property-decorator";
import EventBus from "@/store/eventBus";

interface textStyle {
    opacity: number;
    transform: string;
}

@Component({})
class BeneficiosPage extends Vue {
    // =========== Ciclo de vida ===========
    private mounted(): void {
        this.onResize();
        window.addEventListener("resize", this.onResize, false);
    }

    // =========== Variáveis reativas ===========
    private detailOpened: boolean = false;
    private offsetTop: number = 0;

    // =========== Métodos ===========
    private onResize(): void {
        this.offsetTop = (this.$refs.beneficios as HTMLElement).offsetTop;
    }

    private toogleDetail(): void {
        this.detailOpened = !this.detailOpened;
    }

    // =========== Variáveis computadas ===========
    private get scrollPosition(): number {
        return EventBus.scrollPosition;
    }

    private get position(): number {
        return Math.max(
            Math.min(
                (this.scrollPosition - this.offsetTop) /
                    (window.innerHeight * 0.7),
                1
            ),
            0
        );
    }

    private get isFixed(): boolean {
        return this.scrollPosition < this.offsetTop + window.innerHeight;
    }

    private get hasAnimation(): boolean {
        return this.position < 0.7;
    }
}

export default BeneficiosPage;
