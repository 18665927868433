







































import { Component, Vue } from 'vue-property-decorator';
import animateScrollTo, { IOptions } from 'animated-scroll-to';

@Component({ })
class PilotarMenuMobile extends Vue {
    private mounted(): void {
        window.addEventListener('resize', this.onResize, false);
        this.onResize();
    }

    private menuMaskWidth: number = 0;
    private menuMaskAngle: number = 0;
    private menuOpened: boolean = false;
    private animating: boolean = false;


    public toogleButton(): void {
        if(!this.animating) {

            this.animating = true;
    
            const openButton = document.getElementById('openButton') as HTMLElement;
            const closeButton = this.$refs.closeButton as HTMLElement;
            const menu = (this.$refs.menu as HTMLElement);
            this.menuOpened = !this.menuOpened;
    
            if(this.menuOpened) {
                openButton.classList.remove('--nav-opened-reverse');
                closeButton.classList.remove('--nav-closed-reverse');
                menu.classList.remove('--menu-opened-reverse');
                
                const refresh = openButton.clientHeight;
                const refreshClose = closeButton.clientHeight;
                const refreshMenu = menu.clientHeight;
                
                openButton.classList.add('--nav-opened');
                closeButton.classList.add('--nav-closed');
                menu.classList.add('--menu-opened');
            }
            else {
                openButton.classList.remove('--nav-opened');
                closeButton.classList.remove('--nav-closed');
                menu.classList.remove('--menu-opened');
    
                const refresh = openButton.clientHeight;
                const refreshClose = closeButton.clientHeight;
                const refreshMenu = menu.clientHeight;
                
                openButton.classList.add('--nav-opened-reverse');
                closeButton.classList.add('--nav-closed-reverse');
                menu.classList.add('--menu-opened-reverse');
            }
            const vm = this;
            setTimeout(() => {
                vm.animating = false;
            }, 1500);
        }
    }

    public scroll(page: string): void {
        const options: IOptions = {
            maxDuration: 7000,
            speed: 1000
        };
        if(window.innerWidth <= 1024) {
            this.toogleButton();
        }

        const element: Element = (document.querySelector(page) as Element);

        if(window.innerWidth < 450) {
            animateScrollTo(element, options);
        }
        else {
            element.scrollIntoView();
        }
    }

    
    private onResize(): void {
        // Menu mobile
        const menu = this.$refs.menu as HTMLElement;
        this.menuMaskWidth = Math.sqrt((menu.clientHeight * menu.clientHeight) + (menu.clientWidth * menu.clientWidth));
        this.menuMaskAngle = Math.acos((menu.clientWidth / this.menuMaskWidth)) * 180 / Math.PI;
    }
}

export default PilotarMenuMobile;
