























































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';
import AwesomeMask from 'awesome-mask';
import VsIcon from '@/components/Controls/vs-Icon.vue';


@Component({ components: { ValidationProvider, VsIcon }, directives: { mask: AwesomeMask } })
class VsInput extends Vue {
    private mounted(): void {
        const vm = this;
        this.$parent.$on('validate', () => {
            this.firstValidate = true;
        });
    }

    @Prop({ type: String, default: 'white'})
    private readonly backgroundColor!: string;

    @Prop({ type: String, default: 'var(--gray-2)'})
    private readonly textColor!: string;

    @Prop({ type: String, required: true })
    private readonly placeholder!: string;

    @Prop({ type: String, required: true })
    private readonly value!: string;

    @Prop({ type: String, default: 'text' })
    private readonly type!: string;

    @Prop({ type: String, required: true })
    private readonly name!: string;

    @Prop({ type: Boolean, default: false })
    private readonly readonly!: boolean;

    @Prop({ type: String })
    private readonly rules!: string;

    @Prop({ type: String, default: '' })
    private readonly mask!: string;

    @Prop({ type: Number, default: 30 })
    private readonly maxlength!: number;
    
    @Prop({ type: Boolean, default: false })
    private readonly outline!: boolean;

    // /* =============== Variáveis reativas ============== */

    private isActive: boolean = false;
    private text: string = this.value;
    private showPassword: boolean = false;
    private firstValidate: boolean = false;

    // /* ================= Métodos ================ */
    private selected(e: MouseEvent) {
        if(this.type === 'select') {
            e.preventDefault();
            this.$emit('select');
        }
    }

    @Watch('value')
    private onValueChanged(newValue: string): void {
        if(newValue !== this.text) {
            this.text = newValue;
        }
    }

    private lostFocus(): void {
        if(this.mask.length) {
            if(this.value.length !== this.mask.length) {
                this.$emit('input', '');
            }
        }
        this.isActive = false;
    }
}

export default VsInput;
