














import { Component, Vue, Watch } from "vue-property-decorator";
import EventBus from "@/store/eventBus";

interface AnimationModel {
    el: HTMLElement;
    from: number[];
    to: number[];
    keyframeIncial: number;
    keyframeFinal: number;
    property: string;
    unit: string;
}

@Component({})
class VantagensPage extends Vue {
    private mounted(): void {
        this.onResize();
        window.addEventListener("resize", this.onResize, false);
    }

    private topBreakpoint: number = 0;
    private bottomBreakpoint: number = 0;
    private isAppear: boolean = false;

    private onResize(): void {
        this.topBreakpoint = (this.$refs.vantagens as HTMLElement).offsetTop;
        this.bottomBreakpoint =
            (this.$refs.vantagens as HTMLElement).offsetTop +
            (this.$refs.vantagens as HTMLElement).clientHeight +
            window.innerHeight;
    }

    private get scrollPosition(): number {
        return EventBus.scrollPosition;
    }

    @Watch("scrollPosition")
    private onScrollPositionChanged(): void {
        if (
            this.scrollPosition >= this.topBreakpoint &&
            this.scrollPosition < this.bottomBreakpoint
        ) {
            if (EventBus.scrollDirection == "top") {
                this.isAppear = true;
            } else {
                this.isAppear = this.isAppear
                    ? true
                    : this.scrollPosition >= this.topBreakpoint + 350;
            }
        } else {
            this.isAppear = false;
        }
    }
}

export default VantagensPage;
