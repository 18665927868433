
































import { Component, Vue, Watch } from "vue-property-decorator";

@Component({})
class CnhPage extends Vue {}

export default CnhPage;
